import {initializeApp} from 'firebase/app';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.less';
import NotFound from './components/404/404.component';
import NotAuthorized from './components/not-authorized/not-authorized.component';
import { firebaseConfig } from './config/firebaseConfig';
import { useAuth } from './hooks/useAuth';
import { authRoutesToCheck } from './modules/auth';
import SignupSuccessPage from './modules/auth/signup-success/signup-success.component';
import DesignerModule, { designerRoutesToCheck } from './modules/designer';
import GDPModule, { gdpRoutesToCheck } from './modules/gdp';
import ManufacturerModule, { manufacturerRoutesToCheck } from './modules/manufacturer';
import SpecialistModule, { specialistRoutesToCheck } from './modules/specialist';

const AuthModule = React.lazy(() => import('./modules/auth'));

// initialise firebase app
initializeApp(firebaseConfig);

function App() {
  const { user } = useAuth()
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={() => {
          if (user) {
            return <Redirect to={`/${user.type}`} />
          }

          return <Redirect to='/signin' />
        }}
      />

      <Route exact path='/signup-success'>
        <Suspense fallback={() => <div>Loading...</div>}><SignupSuccessPage /></Suspense>
      </Route>

      <Route exact path={authRoutesToCheck}>
        <Suspense fallback={() => <div>Loading...</div>}><AuthModule /></Suspense>
      </Route>

      <Route exact path={gdpRoutesToCheck}>
          <GDPModule />
      </Route>

      <Route exact path={designerRoutesToCheck}>
          <DesignerModule />
      </Route>
      
      <Route exact path={manufacturerRoutesToCheck}>
          <ManufacturerModule />
      </Route>

      <Route exact path={specialistRoutesToCheck}>
          <SpecialistModule />
      </Route>

        <Route path="/not-authorized" component={NotAuthorized} />

      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default App;
