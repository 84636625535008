import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { User } from './services/authApi'
import type { RootState } from '../../redux/store'

type AuthState = {
  user: User | null
  token: string | null
}

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, token } }: PayloadAction<{ user: User; token: string }>
    ) => {
      state.user = user
      state.token = token
    },
    updateUserData: (
      state,
      { payload: { user } }: PayloadAction<{ user: User }>
    ) => {
      state.user = user
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
    }
  },
})

export const { setCredentials, updateUserData, logout } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user
