import { globalStorage } from './window';
import {api} from './api';
import { IProposalStatuses } from './proposalsApi';
import { formatUrl } from '../helpers';

export const PATIENTS_API_URL = {
    CASES: '/cases',
    LOGIN: '/user/login',
    INVITATIONS: '/cases/invitations',
    ACCEPT_CASE: '/cases/accept',
    REJECT_CASE: '/cases/reject',
    JOURNEY: '/cases/journey',
    LATESTAPPROVEDCASE: '/cases/latest',
    PATIENTS: '/patients',
    INSTRUCTIONS: '/instructions',
};

export interface IClinic {
    id: string,
    name: string
}

export interface IMobile {
    ext: string
    number: string,
}

export interface IPatientDetails {
    firstName: string
    lastName: string,
    mobile: IMobile,
    profilePic: string,
    email: string,
    dob: string,
    id: string
}

export interface IPatinetImage {
    url: string,
    type: string
}

export interface IPatient {
    id: string
    patient: IPatientDetails,
    clinic: IClinic,
    serviceType: string,
    submissionStatus: string,
    status: string,
    createdAt: string,
    caseId: string,
    txnObjective: string[], 
    attachmentDetail: string,
    attachmentAllow: string,
    attachmentTeethToAvoid: string[],
    anteroposteriorRelationship: string,
    anteroposteriorDetail: string,
    incisorRelationshipOverbite: string,
    incisorRelationshipOverjet: string,        
    dentalMidlineUpper: string,
    dentalMidlineLower: string,
    extractions: string[],
    iprTeethToAvoid: string[],
    exclusionsSite: string[],
    upperArchCrowdingResolutions: string[],
    lowerArchCrowdingResolutions: string[],
    extractionsDetail: string,
    dentalAndMedicalFit: string,
    patientMotivationAndConcern: string,
    diagnosis: any,
    iprDetail: string,
    auxiliaries: any,
    passiveAligners: string,
    overcorrectionsDefine: string,
    buttonCutOutStage: string,
    additionalFeaturesWhichApply: string[],
    intraOralImages: IPatinetImage[],
    extraOralImages: IPatinetImage[],
    radioGraphImgs: IPatinetImage[],
    updatedAt: string,
    designerCaseStatus: string, 
    gdpCaseStatus: string,
    designsSubmitted: number,
    latestProposalStatus: IProposalStatuses,
    impressionType: string,
    phasingPreference: string,
    txnObjectiveComments: string,
    buttonCutOuts: string[],
    manufacturerShippingLink: string,
    planType: string,
    refinementInfo: IRefinementInfo,
    specialistCaseStatus: string,
    createdBy: any,
    patientCosmeticDentistryLiteracy: string,
    priorExtensiveGeneralDentalTreatment: string,
    priorFacialAesthetics: string,
    relevantMedicalSocialAndDrugHistory: string,
    bpe: string,
    midlinesUpperDentalVSFacial: string,
    midlinesChinpoint: string,
    lipline: string,
    additionalOrthoComments: string,
    patientStageAtConcerningTreatment: string,
    patientOccupation: string,
    otherModifyingFactors: string,
    dietaryFactors: string,
    priorOrthodontics: string,
    cariesRisk: string,
    toothSurfaceLoss: string,
    perioRisk: string,
    oralHealthPathology: string,
    oralHygiene: string,
    gingivalBiotype: string,
    latestSpecialistProposalStatus: IProposalStatuses,
    instructions: any,
    rejectedReason: any,
    widerTreatmentPlan: any,
    widerTreatmentPlanDetails: string,
    patientSuitability: string,
    bruxer: string,
    statedTimings: string,
    generalDentalTreatmentDetail: string,
    priorOrthoDetails: string,
    priorFacialAestheticsDetails: string,
    BPE: string,
    midlinesCoincident: string,
    midlineComments: string,
    dentistPerspectiveOne: string,
    dentistPerspectiveTwo: string,
    dentistPerspectiveThree: string
    postageDate: string,
    spaceAims: string,
}

export interface IRefinementInfo{
    arches: string,
    stepReached: string,
    stepReachedComments: string,
    treatmentBackground: string,
    treatmentPlanAdditionalComments: string
}

export interface PatientsResponse {
    cases: IPatient[],
    total: number
}
export interface PatientsRequest {
    page: number,
    perPage: number,
    status: string,
    submissionStatus: string,
    q?: string,
    type?: string
}

export interface IPatientJourney {
    draft: IPatient[],
    submitted: IPatient[],
    waitingApproval: IPatient[],
    approved: IPatient[],
    inTreatment: IPatient[],
    completed: IPatient[],
}


export const patientsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPatients: build.query<PatientsResponse, PatientsRequest>({
            query: ({ page, perPage, submissionStatus, status, type, q }) => {
                globalStorage.patientsScreen.urlQueries = { page, perPage, submissionStatus, status, type, q };
                let paramsString = '';
                if (page) paramsString += `page=${page}`;
                if (perPage) paramsString += `&perPage=${perPage}`;
                if (submissionStatus) paramsString += `&submissionStatus=${submissionStatus}`;
                if (status) paramsString += `&status=${status}`;
                if (q) paramsString += `&q=${q}`;

                return formatUrl(`${PATIENTS_API_URL.CASES}?${paramsString}`);
            },
            // Provides a list of `Patients` by `id`.
            // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
            // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Posts` element was added.
            providesTags: (result) =>
                // is result available?
                result
                    ? // successful query
                    [
                        ...result.cases.map(({ id }) => ({ type: 'Patients', id } as const)),
                        { type: 'Patients', id: 'LIST' },
                    ]
                    : // an error occurred, but we still want to refetch this query when `{ type: 'Patients', id: 'LIST' }` is invalidated
                    [{ type: 'Patients', id: 'LIST' }],
            keepUnusedDataFor: 5,
        }),
        addPatient: build.mutation<IPatient, Partial<IPatient>>({
            query(body) {
                return {
                    url: formatUrl(PATIENTS_API_URL.CASES),
                    method: 'POST',
                    body,
                }
            },
            // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
            // that newly created post could show up in any lists.
            invalidatesTags: [{ type: 'Patients', id: 'LIST' }],
        }),
        getPatient: build.query<IPatient, string>({
            query: (id) => formatUrl(`${PATIENTS_API_URL.CASES}/${id}`),
            // providesTags: (result, error, id) => [{ type: 'Patients', id }],
        }),
        updatePatient: build.mutation<IPatient, Partial<IPatient>>({
            query(data) {
                const { id, createdAt, updatedAt, caseId: internalId, designerCaseStatus, gdpCaseStatus, ...body } = data
                return {
                    url: formatUrl(`${PATIENTS_API_URL.CASES}/${id}`),
                    method: 'PUT',
                    body,
                }
            },
            // Invalidates all queries that subscribe to this Patients `id` only.
            // In this case, `getPatients` will be re-run. `getPatients` *might*  rerun, if this id was under its results.
            invalidatesTags: (result, error, { id }) => [{ type: 'Patients', id }],
        }),
        getPatientsJourney: build.query<IPatientJourney, any>({
            query: () => formatUrl(`${PATIENTS_API_URL.JOURNEY}`),
        }),
        getLatestApprovedCase: build.query<any, any>({
            query: () => formatUrl(`${PATIENTS_API_URL.LATESTAPPROVEDCASE}`),
        }),
        getCasesByPatient: build.query<any, any>({
            query: (id) => formatUrl(`${PATIENTS_API_URL.PATIENTS}/${id}${PATIENTS_API_URL.CASES}`),
        }),
        submitInstruction: build.mutation<IPatient, Partial<IPatient>>({
            query(data) {
                const { id, ...body } = data;
                return {
                    url: formatUrl(`${PATIENTS_API_URL.INSTRUCTIONS}`),
                    method: 'POST',
                    body,
                }
            },
            // Invalidates all queries that subscribe to this Patients `id` only.
            // In this case, `getPatients` will be re-run. `getPatients` *might*  rerun, if this id was under its results.
            invalidatesTags: (result, error, { id }) => [{ type: 'Patients', id: 'LIST' }],
        }),
        submitCaseNotSuitable: build.mutation<IPatient, Partial<IPatient>>({
            query(data) {
                const { caseId, ...body } = data;
                return {
                    url: formatUrl(`${PATIENTS_API_URL.CASES}/${caseId}/unsuitable`),
                    method: 'PUT',
                    body,
                }
            },
            // Invalidates all queries that subscribe to this Patients `id` only.
            // In this case, `getPatients` will be re-run. `getPatients` *might*  rerun, if this id was under its results.
            invalidatesTags: (result, error, { id }) => [{ type: 'Patients', id: 'LIST' }],
        }),
        submitAdvice: build.mutation<any, any>({
            query: (credentials) => ({
                url: formatUrl('/advices'),
                method: 'POST',
                body: credentials,
            }),
        }),
        getAdvice: build.query<any, any>({
            query: ({caseId, designId}) => formatUrl(`/advices?case=${caseId}&proposal=${designId}`),
        }),
    }),
})

export const {
    useGetPatientsQuery,
    useAddPatientMutation,
    useGetPatientQuery,
    useUpdatePatientMutation,
    useGetPatientsJourneyQuery,
    useGetLatestApprovedCaseQuery,
    useGetCasesByPatientQuery,
    useSubmitInstructionMutation,
    useSubmitCaseNotSuitableMutation,
    useSubmitAdviceMutation,
    useGetAdviceQuery
} = patientsApi