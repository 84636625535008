import React, {useState} from 'react';
import { Form, Input, Select, Button, AutoComplete } from 'antd';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from "use-places-autocomplete";
import { IDesignerPersonalDetails, IAddress } from '../constants';

const { Option } = Select;

interface IDesignerPersonalDetailsComponentProps {
    initialValues?: IDesignerPersonalDetails,
    submittedValues: Function,
    handleNextButton: Function
}

const initialAddressState: IAddress = {}

const PersonalDetails = ((props: IDesignerPersonalDetailsComponentProps) => {

    const { initialValues } = props;
    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {},
        debounce: 300,
    });
    const [addressObj, setAddressObj]= useState(initialValues?.address || initialAddressState)

    const [form] = Form.useForm();

    const onFinish = (values: IDesignerPersonalDetails) => {
        props.submittedValues('personalDetails', {...values, address: {...addressObj, zipCode: values.address.postCode ? values.address.postCode : addressObj?.postCode, postCode: values.address.postCode ? values.address.postCode : addressObj?.postCode, country: values.address.country ? values.address.country : addressObj?.country }});
        props.handleNextButton();
    }

    const onFinishFailed = ({ errorFields }: any) => {
        form.scrollToField(errorFields[0].name);
    };

    const handleAddressChange = (val: any) => {
        setValue(val);
    }

    const handleAddressSelect = ({ description }: any) => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        // setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then(async (results) => {
                const latLng = await getLatLng(results[0])
                const zipCode = await getZipCode(results[0], false)
                return {...latLng, zipCode}
            })
            .then(({ lat, lng, zipCode }) => {
                const address: IAddress = {};
                address["formattedAddress"] = description
                address["latitude"] = lat
                address["longitude"] = lng
                address["zipCode"] = zipCode
                address["postCode"] = zipCode
                setAddressObj(address)
                form.setFieldsValue({
                    address
                })
            })
            .catch((error) => {
                console.log("😱 Error: ", error);
            });
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={initialValues}
            layout="vertical"
            size="large"
            className="mt-3 fadein animation-duration-500"
        >
            <div className="grid">
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="firstName" label="First Name *"
                        rules={[
                            {
                                required: true,
                                message: 'Please input first name',
                            },
                        ]}
                    >
                        <Input placeholder="John" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item
                        name="lastName"
                        label="Last Name *"
                        // hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input last name',
                            },
                        ]}
                    >
                        <Input placeholder="Doe" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name={["email", "email"]} label="Email Address *"
                        rules={[
                            {
                                required: true,
                                message: 'Please input email address',
                            },
                            { type: 'email', message: 'Please enter valid email address' }
                        ]}
                    >
                        <Input placeholder="Enter your email address" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="designLabName" label="Design Lab Name">
                        <Input placeholder="Enter Design Lab Name" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item
                        name="password"
                        label="Password *"
                        // hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input password',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Enter Password" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item
                        name="confirmPassword"
                        label="Confirm Password *"
                        dependencies={['password']}
                        // hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input confirm password',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            })
                        ]}
                    >
                        <Input.Password placeholder="Enter Confirm Password" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item label="Mobile Number *"
                    // noStyle
                    >
                        <Input.Group compact>
                            <Form.Item
                                name={['mobile', 'ext']}
                                noStyle
                                rules={[{ required: true, message: 'ext is required' }]}
                            >
                                <Select style={{ width: '30%' }} placeholder="ext">
                                    <Option value="+44">
                                        <img src="https://cdn-icons-png.flaticon.com/512/555/555417.png" alt="UK falg" style={{ width: 20 }} className="mr-1" /> +44
                                    </Option>
                                    <Option value="+1">
                                        <img src="https://cdn-icons-png.flaticon.com/512/206/206626.png" alt="US falg" style={{ width: 20 }} className="mr-1" /> +1
                                    </Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={['mobile', 'number']}
                                noStyle
                                rules={[{ required: true, message: 'Please enter your mobile number' }]}
                            >
                                <Input style={{ width: '70%' }} placeholder="Enter your mobile number" />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name={["address", "formattedAddress"]} label="Business Address">
                        {/* <Input placeholder="Enter first line of your address" /> */}
                        <AutoComplete value={value} onChange={handleAddressChange} disabled={!ready} placeholder="Enter clinic address">
                            {status === "OK" && data.map((suggestion) => {
                                const { place_id, structured_formatting: { main_text, secondary_text },
                                } = suggestion;
                                return (
                                    <AutoComplete.Option className="places-options" key={place_id} onClick={() => handleAddressSelect(suggestion)} value={`${main_text} ${secondary_text ? secondary_text : ''}`}>
                                        {/* <strong>{main_text}</strong>&nbsp;<small>{secondary_text}</small> */}
                                        <li key={place_id} onClick={() => handleAddressSelect(suggestion)}>
                                            <strong>{main_text}</strong> <small>{secondary_text}</small>
                                        </li>
                                    </AutoComplete.Option>

                                );
                            })}
                        </AutoComplete>
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name={["address", "postCode"]} label="Business Postcode">
                        <Input placeholder="Enter your postcode"/>
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name={["address", "country"]} label="Business Country">
                        <Input placeholder="Enter country" />
                    </Form.Item>
                </div>
            </div>
            <div className="steps-action">
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit">
                        Next
                    </Button>
                </Form.Item>
            </div>
        </Form>
    )
})

export { PersonalDetails }