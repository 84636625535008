import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, DatePicker, Select, Button } from 'antd';
import { DENTAL_SCHOOL_LIST, UK_PHONE_NUMBER_REGEX, US_PHONE_NUMBER_REGEX } from '../../../config/constants';
import type { IPersonalDetails } from '../constants';
import moment from 'moment';

const { Option } = Select;

interface IPersonalDetailsComponentProps {
    initialValues?: IPersonalDetails,
    submittedValues: Function,
    handleNextButton: Function
}

const PersonalDetails = ((props: IPersonalDetailsComponentProps) => {
    
    const { initialValues } = props;
    const [showDentalSchoolNameInput, setShowDentalSchoolNameInput] = useState(initialValues?.dentalSchool === 'Other' ? true : false)

    const [form] = Form.useForm();

    const onFinish = (values: IPersonalDetails) => {
        props.submittedValues('personalDetails', values);
        props.handleNextButton();
    }

    const onFinishFailed = ({ errorFields }: any) => {
        form.scrollToField(errorFields[0].name);
    };

    const _handleDentalSchoolNameSelect = (text: any) => {
        if(text === 'Other') {
            setShowDentalSchoolNameInput(true);
        } else {
            if(showDentalSchoolNameInput) setShowDentalSchoolNameInput(false);
        }
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={initialValues}
            layout="vertical"
            size="large"
            className="mt-3 fadein animation-duration-500"
        >
            <div className="grid">
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="firstName" label="First Name *"
                        rules={[
                            {
                                required: true,
                                message: 'Please input first name',
                            },
                        ]}
                    >
                        <Input placeholder="John" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item
                        name="lastName"
                        label="Last Name *"
                        // hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input last name',
                            },
                        ]}
                    >
                        <Input placeholder="Doe" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item
                        name="password"
                        label="Password *"
                        // hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input password',
                            },
                            {
                                min: 8,
                                message: "Minimum 8 characters"
                            }
                        ]}
                    >
                        <Input.Password placeholder="Enter Password" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item
                        name="confirmPassword"
                        label="Confirm Password *"
                        dependencies={['password']}
                        // hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input confirm password',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            })
                        ]}
                    >
                        <Input.Password placeholder="Enter Confirm Password" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="dob" label="Date of Birth *"
                        rules={[
                            {
                                required: true,
                                message: 'Please input date of birth',
                            },
                        ]}
                    >
                        <DatePicker 
                            style={{ width: '100%' }} 
                            format="DD/MM/YYYY" 
                            placeholder="DD/MM/YYYY" 
                            // defaultValue={initialValues?.dob ? null : moment(initialValues?.dob, "DD/MM/YYYY")}
                        />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name={["email", "email"]} label="Email Address *"
                        rules={[
                            {
                                required: true,
                                message: 'Please input email address',
                            },
                            { type: 'email', message: 'Please enter valid email address' }
                        ]}
                    >
                        <Input placeholder="Enter your email address" className='lowercase' />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item label="Mobile Number (Personal) *"
                    // noStyle
                    >
                        <Input.Group compact>
                            <Form.Item
                                name={['mobile', 'ext']}
                                noStyle
                                rules={[{ required: true, message: 'ext is required' }]}
                            >
                                <Select style={{ width: '30%' }} placeholder="ext">
                                    <Option value="+44">
                                        <img src="https://cdn-icons-png.flaticon.com/512/555/555417.png" alt="UK falg" style={{ width: 20 }} className="mr-1" /> +44
                                    </Option>
                                    <Option value="+1">
                                        <img src="https://cdn-icons-png.flaticon.com/512/206/206626.png" alt="US falg" style={{ width: 20 }} className="mr-1" /> +1
                                    </Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={['mobile', 'number']}
                                noStyle
                                rules={[
                                    { required: true, message: 'Please enter your mobile number' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const selectedRegion = getFieldValue(['mobile', 'ext'])
                                            if(!selectedRegion) {
                                                return Promise.reject(new Error('Please select ext!'));
                                            } else {
                                                const regex = selectedRegion === '+44' ? UK_PHONE_NUMBER_REGEX : US_PHONE_NUMBER_REGEX;
                                                if (!value || regex.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Please enter valid phone number!'));
                                            }
                                        },
                                    })
                                ]}
                            >
                                <InputNumber style={{ width: '70%' }} placeholder="Enter your mobile number" stringMode />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="gdcNumber" label="GDC Registration Number *"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your GDC number',
                            },
                        ]}
                    >
                        <Input placeholder="Enter your GDC number" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="dentalSchool" label="Dental School *"
                        rules={[
                            {
                                required: true,
                                message: 'Please select your dental school',
                            },
                        ]}
                    >
                        <Select showSearch placeholder="Select your dental school" onChange={selectedValue => _handleDentalSchoolNameSelect(selectedValue)}>
                            {
                                DENTAL_SCHOOL_LIST.map(item => <Option key={item} value={item}>{item}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </div>
                {showDentalSchoolNameInput &&
                    <div className="col-12 sm:col-12 md:col-6">
                        <Form.Item name="dentalSchoolName" label="Dental School Name *"
                            rules={[
                                {
                                    required: true,
                                    message: 'Missing dental school name!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter your dental school name" />
                        </Form.Item>
                    </div>
                }
            </div>
            <div className="steps-action">
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit">
                        Next
                    </Button>
                </Form.Item>
            </div>
        </Form>
    )
})

export default PersonalDetails;