import React from 'react';
import { Form, Input, Button, Select, Tooltip, AutoComplete, InputNumber } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import type { IAddress, IPractises } from '../constants';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from "use-places-autocomplete";
import { UK_PHONE_NUMBER_REGEX, US_PHONE_NUMBER_REGEX } from '../../../config/constants';
const { Option } = Select;

interface IPractisesFormComponentProps {
    initialValues?: IPractises[],
    submittedValues: Function,
    handleNextButton: Function,
    handleBackButton: Function
}

const registeredWith32s = [{
    value: "true",
    label: "Yes"
}, {
    value: "false",
    label: "No"
}]

const PractisesForm = ((props: IPractisesFormComponentProps) => {

    const { initialValues } = props;

    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {},
        debounce: 300,
    });

    const [form] = Form.useForm();

    const onFinish = (values: IPractises[]) => {
        props.submittedValues('practises', values);
        props.handleNextButton();
    }

    const onFinishFailed = ({ errorFields }: any) => {
        form.scrollToField(errorFields[0].name);
    };

    const storeValues = async () => {
        const values = await form.getFieldsValue();
        props.submittedValues('practises', values);
        props.handleBackButton();
    }

    const handleAddressChange = (val: any) => {
        setValue(val);
    }

    const handleAddressSelect = ({ description }: any) => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        // setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then(async (results) => {
                const latLng = await getLatLng(results[0])
                const zipCode = await getZipCode(results[0], false)
                return {...latLng, zipCode}
            })
            .then(({ lat, lng, zipCode }) => {
                const address: IAddress = {};
                address["formattedAddress"] = description
                address["latitude"] = lat
                address["longitude"] = lng
                address["zipCode"] = zipCode
                address["postCode"] = zipCode

                form.setFieldsValue({
                    address
                })
            })
            .catch((error) => {
                console.log("😱 Error: ", error);
            });
    }

    return (
        <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={initialValues}
            layout="vertical"
            size="large"
            className="mt-3"
        >
            <Form.List name="clinics">
                {(fields, { add, remove }) => (
                    <>
                        <div className="add-another-btn-container mb-3">
                            <Button type="default" size="large" onClick={() => add()}>
                                ADD ANOTHER CLINIC
                            </Button>
                        </div>
                        {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                            <div className={`grid ${index > 0 ? 'mt-3 border-top mb-2' : ''}`}>
                                {index > 0 &&
                                    <div className="col-12 mt-2">
                                        <div className="item-remove-container">
                                            <div className="practises-text-container">
                                                <span>Practise {index + 1}</span>
                                                {/* <HalfBorder width={45}/> */}
                                            </div>
                                            <Tooltip title="Delete">
                                                <Button shape="circle" icon={<CloseOutlined />} onClick={() => remove(name)} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                }
                                <div className="col-12 sm:col-12 md:col-6">
                                    <Form.Item
                                        {...restField}
                                        label="Clinic Name *"
                                        name={[name, 'clinicName']}
                                        fieldKey={[fieldKey, 'name']}
                                        rules={[{ required: true, message: 'Missing clinic name' }]}
                                    >
                                        <Input placeholder="Enter Clinic Name" />
                                    </Form.Item>
                                </div>
                                <div className="col-12 sm:col-12 md:col-6">
                                    {/* <Form.Item
                                        {...restField}
                                        label="Clinic Phone number *"
                                        name={[name, 'phoneNumber']}
                                        fieldKey={[fieldKey, 'phoneNumber']}
                                        rules={[{ required: true, message: 'Missing Clinic Phone number' }]}
                                    >
                                        <Input placeholder="Enter clinic phone number" />
                                    </Form.Item> */}
                                    <Form.Item label="Clinic Phone Number *"
                                    // noStyle
                                    >
                                        <Input.Group compact>
                                            <Form.Item
                                                name={[name, 'mobile', 'ext']}
                                                fieldKey={[fieldKey, 'ext']}
                                                noStyle
                                                rules={[{ required: true, message: 'ext is required' }]}
                                            >
                                                <Select style={{ width: '30%' }} placeholder="ext">
                                                    <Option value="+44">
                                                        <img src="https://cdn-icons-png.flaticon.com/512/555/555417.png" alt="UK falg" style={{ width: 20 }} className="mr-1" /> +44
                                                    </Option>
                                                    <Option value="+1">
                                                        <img src="https://cdn-icons-png.flaticon.com/512/206/206626.png" alt="US falg" style={{ width: 20 }} className="mr-1" /> +1
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name={[name, 'mobile', 'number']}
                                                fieldKey={[fieldKey, 'number']}
                                                noStyle
                                                rules={[
                                                    { required: true, message: 'Missing clinic phone number' },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            console.log(name, 'mobile', 'ext');
                                                            const selectedRegion = getFieldValue(['clinics', name, 'mobile', 'ext'])
                                                            if(!selectedRegion) {
                                                                return Promise.reject(new Error('Please select ext!'));
                                                            } else {
                                                                const regex = selectedRegion === '+44' ? UK_PHONE_NUMBER_REGEX : US_PHONE_NUMBER_REGEX;
                                                                if (!value || regex.test(value)) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('Please enter valid phone number!'));
                                                            }
                                                        },
                                                    })
                                                ]}
                                            >
                                                <InputNumber style={{ width: '70%' }} placeholder="Enter clinic phone number" stringMode />
                                            </Form.Item>
                                        </Input.Group>
                                    </Form.Item>
                                </div>
                                <div className="col-12 sm:col-12 md:col-6">
                                    <Form.Item
                                        {...restField}
                                        label="Is this Clinic Registered with 32Co? *"
                                        name={[name, 'registeredWith32s']}
                                        fieldKey={[fieldKey, 'registeredWith32s']}
                                        rules={[{ required: true, message: 'Missing clinic registered' }]}
                                    >
                                        <Select placeholder="Select option">
                                            {registeredWith32s.map(({ value, label }) => <Option value={value}>{label}</Option>)}
                                            {/* <Option value="true">YES</Option>
                                            <Option value="false">NO</Option> */}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-12 sm:col-12 md:col-6">
                                    <Form.Item
                                        {...restField}
                                        label="Clinic Address *"
                                        name={[name, 'address', 'formattedAddress']}
                                        fieldKey={[fieldKey, 'address']}
                                        rules={[{ required: true, message: 'Missing clinic address' }]}
                                    >
                                        {/* <Input placeholder="Enter clinic address" /> */}
                                        {/* import { AutoComplete } from 'antd'; */}
                                        <AutoComplete value={value} onChange={handleAddressChange} disabled={!ready} placeholder="Enter clinic address">
                                            {status === "OK" && data.map((suggestion) => {
                                                const { place_id, structured_formatting: { main_text, secondary_text },
                                                } = suggestion;
                                                return (
                                                    <AutoComplete.Option className="places-options" key={place_id} onClick={() => handleAddressSelect(suggestion)} value={`${main_text} ${secondary_text ? secondary_text : ''}`}>
                                                        {/* <strong>{main_text}</strong>&nbsp;<small>{secondary_text}</small> */}
                                                        <li key={place_id} onClick={() => handleAddressSelect(suggestion)}>
                                                            <strong>{main_text}</strong> <small>{secondary_text}</small>
                                                        </li>
                                                    </AutoComplete.Option>

                                                );
                                            })}
                                        </AutoComplete>
                                    </Form.Item>
                                </div>
                                <div className="col-12 sm:col-12 md:col-6">
                                    <Form.Item
                                        {...restField}
                                        label="Clinic Manager *"
                                        name={[name, 'manager', "name"]}
                                        fieldKey={[fieldKey, 'name']}
                                        rules={[{ required: true, message: 'Missing manager name' }]}
                                    >
                                        <Input placeholder="Enter manager name" />
                                    </Form.Item>
                                </div>
                                <div className="col-12 sm:col-12 md:col-6">
                                    <Form.Item
                                        {...restField}
                                        label="Clinic Manager Email *"
                                        name={[name, 'manager', 'email']}
                                        fieldKey={[fieldKey, 'email']}
                                        rules={[{ required: true, message: 'Missing manager email' }, { type: 'email', message: 'Please enter valid email address' }]}
                                    >
                                        <Input placeholder="Enter manager email" className='lowercase' />
                                    </Form.Item>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </Form.List>
            <div className="steps-action">
                <Form.Item>
                    <Button type="default" className="previoud-btn" onClick={storeValues} >
                        Back
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Next
                    </Button>
                </Form.Item>
            </div>
        </Form>
    )
})

export default PractisesForm;