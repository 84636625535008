// import firebase, { ServiceAccount } from 'firebase-admin';
// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs, updateDoc, setDoc } from 'firebase/firestore/lite';
// import { formatUrl } from '../helpers';
import { FIREBASE } from './constants';
// import { getDatabase, ref, set, onValue, update } from "firebase/database";

export const firebaseConfig = {
    apiKey: FIREBASE.FIREBASE_APIKEY,
    authDomain: FIREBASE.FIREBASE_AUTHDOMAIN,
    projectId: FIREBASE.FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE.FIREBASE_STORAGEBUCKET,
    messagingSenderId: FIREBASE.FIREBASE_MESSAGINGSENDERID,
    appId: FIREBASE.FIREBASE_APPID,
    measurementId: FIREBASE.FIREBASE_MEASUREMENTID
};

// let firebaseApp: any = null;

// export const firebase = () => {
//     initializeApp(firebaseConfig);
// }
// const db = getFirestore(firebaseApp);
// const conversations = collection(db, 'conversations');
// }


// export async function getCollections(id: any) {
//     const queryCollection = collection(db, `conversations/${id}/messages`);
//     const snapshot = await getDocs(queryCollection);
//     const list = snapshot.docs.map(doc => doc.data());
//     console.log("list", list);

//     const dbConn = getDatabase();
//     const starCountRef = ref(dbConn, 'conversations/' + id + '/messages');
//     console.log("starCountRef", starCountRef);
//     onValue(starCountRef, (snapshotVal) => {
//         console.log("snapshotVal", snapshotVal);
//         const messageValue = snapshotVal.val();
//         console.log("messageValue----------------", messageValue);
//         // updateStarCount(postElement, messageValue);
//     });




//     return list;
// }

// export const updateCollections = async (id: any, formValue: any) => {
//     const db = getDatabase();
//     let updates: any = {};

//     updates[`conversations/${id}/messages`] = formValue;

//     update(ref(db), updates);

    // 
    // set(ref(db, `conversations/${id}/messages`), {
    //   message: formValue
    // }).then(() => {
    //     console.log("res 200!")
    // }).catch((error) => {
    //     console.log("error", error);
    // });

    // console.log("formValue", formValue)
    // console.log("id", id)
    // const updateMessages = await updateDoc(queryCollection, formValue)
    // return updateMessages;
// }




