// import IRoute from "../interfaces/route"
import React, {Suspense} from "react";
import { USER_ROLES } from "../../config/constants";
import IRoute from "../../interface/IRoute";
const LazyDashboardComponent = React.lazy(() => import('./dashboard/dashboard.component'));
const LazyCaseDetailsComponent = React.lazy(() => import('./dashboard/case-details/case-details.component'));

const manufacturerRoutes: IRoute[] = [
    {
        path: '/manufacturer',
        exact: true,
        name: 'Dashboard',
        key: 'dashboard_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyDashboardComponent /></Suspense>),
        role: USER_ROLES.MANUFACTURER
    },
    {
        path: '/manufacturer/cases/:id',
        exact: true,
        name: 'Details',
        key: 'case_details_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyCaseDetailsComponent /></Suspense>),
        role: USER_ROLES.MANUFACTURER
    },
]

export default manufacturerRoutes;