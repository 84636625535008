// import IRoute from "../interfaces/route"
import React, {Suspense} from "react";
import { USER_ROLES } from "../../config/constants";
import IRoute from "../../interface/IRoute";
const LazyDashboardComponent = React.lazy(() => import('./dashboard/dashboard.component'));
const LazyPatientsComponent = React.lazy(() => import('./patients/patients.component'));
const LazyPatientDetailsComponent = React.lazy(() => import('./patients/patient-details/patient-details.component'));
const LazyPatientSubmitComponent = React.lazy(() => import('./submit-instructions/submit-instructions.component'));
const LazyPatientRejectComponent = React.lazy(() => import('./case-not-suitable/case-not-suitable.component'));
const LazyPatientAdviceComponent = React.lazy(() => import('./submit-advice/submit-advice.component'));
const LazyPatientTreatmentDesignComponent = React.lazy(() => import('./patients/patient-details/treatment-proposal/treatment-proposal-details.component'));

const specialistRoutes: IRoute[] = [
    {
        path: '/specialist',
        exact: true,
        name: 'Dashboard',
        key: 'dashboard_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyDashboardComponent /></Suspense>),
        role: USER_ROLES.SPECIALIST
    },
    {
        path: '/specialist/patients',
        exact: true,
        name: 'Patients',
        key: 'patients_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyPatientsComponent /></Suspense>),
        role: USER_ROLES.SPECIALIST
    },
    {
        path: '/specialist/patients/:caseId',
        exact: true,
        name: 'PatientDetails',
        key: 'patient_details_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyPatientDetailsComponent /></Suspense>),
        role: USER_ROLES.SPECIALIST
    },
    {
        path: '/specialist/patients/:caseId/submit',
        exact: true,
        name: 'PatientDetails',
        key: 'patient_details_submit_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyPatientSubmitComponent /></Suspense>),
        role: USER_ROLES.SPECIALIST
    },
    {
        path: '/specialist/patients/:caseId/reject',
        exact: true,
        name: 'PatientDetails',
        key: 'patient_details_reject_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyPatientRejectComponent /></Suspense>),
        role: USER_ROLES.SPECIALIST
    },
    {
        path: '/specialist/patients/:caseId/design/:designId',
        exact: true,
        name: 'PatientDetails',
        key: 'patient_details_td_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyPatientTreatmentDesignComponent /></Suspense>),
        role: USER_ROLES.SPECIALIST
    },
    {
        path: '/specialist/patients/:caseId/design/:designId/advice',
        exact: true,
        name: 'PatientDetails',
        key: 'patient_advice_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyPatientAdviceComponent /></Suspense>),
        role: USER_ROLES.SPECIALIST
    },
]

export default specialistRoutes;