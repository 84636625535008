import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {notification} from 'antd';
import { PersonalDetailsForm } from './personal-details.component';
import { AccountDetailsForm } from './account-details.component';
import AuthLayout from '../../../components/layout/auth-layout/auth-layout.component';
import '../dentist-signup/signup.styles.less';
import { useRegisterMutation } from '../services/authApi';

const SpecialistSignupPage = () => {
    const [step, setStep] = useState(0);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formData, setFormData]: any = useState({ type: 'specialist' })
    const { push } = useHistory();
    const [register, { isLoading }] = useRegisterMutation();


    const handleNextButton = () => {
        setStep(step + 1);
    }

    const handleBackButton = () => {
        setStep(step - 1);
    }

    const handleSubmitButton = async (values: any) => {
        setFormSubmitting(true);
        const { type, exp } = formData;
        const { profilePic, ...bankDetails } = values;
        const { confirmPassword, business, ...personalDetails } = formData.personalDetails;
        const data = {
            type,
            personalDetails, 
            profilePic,
            business,
            bankDetails: bankDetails
        }
        try {
            const response = await register(data).unwrap();
            if(response) {
                push('/signup-success');
            }
        } catch (error: any) {
            if(error) {
                error.data.errors.map((err: any) => {
                    notification.error({
                        message: 'Error',
                        description: err.message,
                        placement: 'topRight'
                    });
                })
            }
        }
        setFormSubmitting(false);
    }

    const getStepValue = (key: string, values: object) => {
        const _copyFormData = {
            ...formData,
            [key]: values
        }
        setFormData(_copyFormData);
    }


    return (
        <AuthLayout>
            <div className="signup-page">
                <div className="p-grid p-mt-0">
                    <div className="p-col-12">
                        <div className="signup-right-container">
                            {/* <div className="have-account-container">
                                <span>Already have account?</span>
                                <Link to="/signin"><span className="sign-in-text p-ml-2">Sign In</span></Link>
                            </div> */}
                            <div className="signup-form-container">
                                <h2 className="signup-text">Specialist Sign Up</h2>
                                {/* <HalfBorder width={45} /> */}

                                <div className="steps-content">
                                    {step === 0 && <PersonalDetailsForm initialValues={formData['personalDetails'] || {}} submittedValues={getStepValue} handleNextButton={handleNextButton} />}
                                    {step === 1 && <AccountDetailsForm initialValues={formData['bankDetails']} submittedValues={getStepValue} handleNextButton={handleSubmitButton} handleBackButton={handleBackButton} formSubmitting={formSubmitting} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}

export default SpecialistSignupPage;