import {combineReducers} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { api } from '../services/api'
import { patientsApi } from '../services/patientsApi'
import authReducer from '../modules/auth/authSlice'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}

const reducers = combineReducers({
    [api.reducerPath]: api.reducer,
    [patientsApi.reducerPath]: patientsApi.reducer,
    auth: authReducer,
})

export const middlewares = [
    api.middleware
]

export default persistReducer(persistConfig, reducers);