import React, { FC } from "react";
import './auth-layout.styles.less';
// import logo_full_white from '../../assets/images/logo_full_white.png'
// import {ReactComponent as Logo} from '../../../assets/images/logo_white.svg';
import logo from '../../../assets/images/logo_white.svg';

const AuthLayout: FC<{}> = ({ children }) => {

    return (
        <div className="auth-layout">
            <div className="left-container px-6 py-6">
                <div className="logo-container">
                    <img src={logo} alt="32Co logo" style={{ width: 130 }} />
                </div>
                <h2 className="why-32s-text">Why 32Co?</h2>
                {/* <HalfBorder width={110} /> */}
                <span className="signup-content-text p-mt-3 mt-2">
                    We are scaling orthodontic knowledge to help every dentist provide better orthodontic care for their patients.
                </span>
                <span className="signup-content-text mt-4">
                    On the platform you’ll have access to:
                </span>
                <div className="flex">
                    <div>
                        <div className="radio-box"></div>
                    </div>
                    <div className="flex flex-grow-1">
                        <span className="signup-content-text">
                            Clinical training based on your level of experience (machine-learning makes sure this content is always relevant for you)
                        </span>
                    </div>
                </div>
                <div className="flex">
                    <div className="radio-box"></div>
                    <span className="signup-content-text">
                        Specialist orthodontists working 1:1 with you on cases (if you wish)
                    </span>
                </div>
                <div className="flex">
                    <div className="radio-box"></div>
                    <span className="signup-content-text">
                        A marketplace of clear aligner manufacturers to purchase your aligners
                    </span>
                </div>
                <span className="signup-content-text p-mt-3 p-mb-3 mt-5">
                    Whether you are new to orthodontics or already experienced, the platform contains everything you need to provide exceptional clear aligner treatment for patients.
                </span>
                <span className="signup-content-text mt-2">
                    Oh - and it’s free to join (but we will check that you are a registered dentist).
                </span>
            </div>
            <div className="right-container">
                {children}
            </div>
        </div>
    )
}

export default AuthLayout;