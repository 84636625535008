import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
// import { Switch, Route } from 'react-router-dom'
import DashboardLayout from '../../components/layout/dashboard-layout/dashboard-layout.component';
import AuthenticatedRoute from '../../components/PrivateRoute';
import { useGetUserProfileMutation } from '../../services/api';
import { updateUserData } from '../auth/authSlice';
import gdpRoutes from './gdp-routes';

const GDPModule = () => {

    const dispatch = useDispatch();
    const [getUserProfile, { isLoading }] = useGetUserProfileMutation();

    useEffect(() => {
        const loadProfile = async () => {
            const userResponse: any = await getUserProfile({}).unwrap()
            dispatch(updateUserData(userResponse))
        }
        loadProfile();
    }, [])

    return (
        <DashboardLayout>
            {gdpRoutes.map(route => (
                <AuthenticatedRoute
                    key={route.key}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    component={route.component}
                    role={route.role}
                />
            ))}
        </DashboardLayout>
    )
}

const nestedRoutes = [
    '/dentist/patients/:caseId',
    '/dentist/patients/:caseId/design/:designId'
]

export const gdpRoutesToCheck = gdpRoutes.map(route => route.path).concat(...nestedRoutes);

export default GDPModule;