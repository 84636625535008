import { notification } from 'antd';
import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import AuthLayout from '../../../components/layout/auth-layout/auth-layout.component';
import { useRegisterMutation } from '../services/authApi';
import ExperienceForm from "./experience.component";
import PersonalDetails from "./personal-details.component";
import PractisesForm from "./practises.component";
import PreferencesForm from "./preferences.component";
import './signup.styles.less';

export const SignupComponent = () => {

    const [step, setStep] = useState(0);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formData, setFormData]: any = useState({ type: 'dentist', practises: { clinics: [{ name: null, mobile: { ext: null, number: null }, registeredWith32s: null, address: null, manager: { name: '', email: '' } }] } })
    const [register, { isLoading }] = useRegisterMutation();
    const { push } = useHistory();

    const handleNextButton = () => {
        setStep(step + 1);
    }

    const handleBackButton = () => {
        setStep(step - 1);
    }

    const handleSubmitButton = async (values: any) => {
        setFormSubmitting(true);
        const { type, personalDetails, exp, practises } = formData
        const { confirmPassword, email, ...details } = personalDetails;
        const { referredCode, ...preferences } = values
        const data = {
            type,
            personalDetails: {
                ...details,
                email: {
                    email: email.email?.toLowerCase()
                }
            },
            exp,
            clinics: practises.clinics.map((clinic: any) => {
                return {
                    ...clinic,
                    manager: {
                        ...clinic.manager,
                        email: clinic.manager.email.toLowerCase()
                    },
                    registeredWith32s: clinic.registeredWith32s === 'true' ? true : false
                }
            }),
            preferences,
            referredCode
        }

        try {
            const response = await register(data).unwrap();
            if (response) {
                push('/signup-success');
            }
        } catch (error: any) {
            if (error) {
                error.data.errors.map((err: any) => {
                    notification.error({
                        message: 'Error',
                        description: err.message,
                        placement: 'topRight'
                    });
                })
            }
        }
        setFormSubmitting(false);
    }

    const getStepValue = (key: string, values: object) => {
        const _copyFormData = {
            ...formData,
            [key]: values
        }
        setFormData(_copyFormData);
    }

    return (
        <AuthLayout>
            <div className="signup-page">
                <div className="grid mt-0">
                    <div className="col-12">
                        <div className="signup-right-container">
                            <div className="have-account-container flex justify-content-end">
                                <span>Already have account?</span>
                                <Link to="/signin"><span className="sign-in-text ml-2">Sign In</span></Link>
                            </div>
                            <div className="signup-form-container">
                                <h2 className="form-heading font-bold">Signup</h2>
                                <div className="steps-content">
                                    {step === 0 && <PersonalDetails initialValues={formData['personalDetails'] || {}} submittedValues={getStepValue} handleNextButton={handleNextButton} />}
                                    {step === 1 && <ExperienceForm initialValues={formData['exp']} submittedValues={getStepValue} handleNextButton={handleNextButton} handleBackButton={handleBackButton} />}
                                    {step === 2 && <PractisesForm initialValues={formData['practises']} submittedValues={getStepValue} handleNextButton={handleNextButton} handleBackButton={handleBackButton} />}
                                    {step === 3 && <PreferencesForm initialValues={formData['preferences']} submittedValues={getStepValue} handleNextButton={handleSubmitButton} handleBackButton={handleBackButton} formSubmitting={formSubmitting} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
}

export default SignupComponent