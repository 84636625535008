export const CustomWindow = (window as any);

interface IGlobal{
    patientDetailScreen?: any,
    patientsScreen?: any,
    manufacturerScreen?: any,
    specialistScreen?: any,
}

export const globalStorage: IGlobal = {
    ...CustomWindow,
    patientDetailScreen: {},
    patientsScreen: {},
    manufacturerScreen: {},
    specialistScreen: {},
} 