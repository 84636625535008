import { FC, useState, useEffect, createElement } from 'react'
import { useDispatch } from 'react-redux'
import { Layout, Menu, Dropdown, Avatar, Typography, Drawer } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined
} from '@ant-design/icons';
import { useAuth } from '../../../hooks/useAuth'
import { logout } from '../../../modules/auth/authSlice';
import { ReactComponent as LogoSVG } from '../../../assets/images/logo_black.svg'
import { ReactComponent as DashboardSVG } from '../../../assets/images/Dashboard.svg';
import { ReactComponent as PatientSVG } from '../../../assets/images/Patients.svg';
import { ReactComponent as MyOrdersSVG } from '../../../assets/images/My_orders.svg';
import { ReactComponent as ResourcesSVG } from '../../../assets/images/Resources.svg';
import './dashboard-layout.styles.less';
import { USER_ROLES } from '../../../config/constants';
import { useAppDispatch } from '../../../hooks/store';

const { Header, Content, Footer, Sider } = Layout;

const DashboardLayout: FC = ({ children }) => {
    const { user } = useAuth();
    const history = useHistory();
    const [activeMenu, setActiveMenu] = useState(user?.type || 'dentist');
    const [collapsed, setCollapsed] = useState(true);
    const [showDrawer, setShowDrawer] = useState(false);
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {

        const getActiveMenu = () => {
            const path = location.pathname;
            if (path.includes('patients')) {
                return 'patients';
            } else if (path.includes('orders')) {
                return 'orders'
            } else {
                return user?.type || 'dentist'
            }
        }

        const actMenu = getActiveMenu();
        setActiveMenu(actMenu);

    }, [location, user]);

    const toggleSidebar = () => {
        setCollapsed(c => !c);
    }

    const toggleSideDrwer = () => {
        setShowDrawer(!showDrawer);
    }

    const onClose = () => {
        setShowDrawer(false);
    };

    const logoutUser = () => {
        dispatch(logout())
    }

    const userProfile = () => {
        history.push('/profile');
    }

    const menu = (
        <Menu style={{ minWidth: 120 }}>
            <Menu.Item onClick={userProfile}>
                Profile
            </Menu.Item>
            <Menu.Item onClick={logoutUser}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout className="layout">
            <Sider
                className="sidebar custom-shadow hidden xl:block"
                trigger={null}
                collapsible
                collapsed={collapsed}
            >
                <div className="mt-3 mb-3 flex justify-content-center">
                    <Link to='/'><LogoSVG /></Link>
                </div>
                <div className="side-nav">
                    <Menu theme="light" defaultSelectedKeys={[activeMenu]} selectedKeys={[activeMenu]} mode="inline">
                        <Menu.Item key={user?.type || 'dentist'} icon={<DashboardSVG />}>
                            <Link to={`/`}>Dashboard</Link>
                        </Menu.Item>
                        {
                            user?.type !== USER_ROLES.MANUFACTURER && (
                                <Menu.Item key="patients" icon={<PatientSVG />}>
                                    <Link to={`/${user?.type}/patients`}>Patients</Link>
                                </Menu.Item>
                            )
                        }
                        {
                            user?.type === USER_ROLES.DENTIST && (
                                <>
                                    {/* <Menu.Item key="orders" icon={<MyOrdersSVG />}>
                                        <Link to={`/${user?.type}/orders`}>My Orders</Link>
                                    </Menu.Item>
                                    <Menu.Item key="resources" icon={<ResourcesSVG />}>
                                        <Link to={`/${user?.type}/resources`}>Resources</Link>
                                    </Menu.Item> */}
                                </>
                            )
                        }
                    </Menu>
                </div>
            </Sider>

            <Drawer className="sidebar xl:hidden" placement="left" onClose={onClose} visible={showDrawer} bodyStyle={{padding: 0}} closable={false}>
                <div className="mt-3 mb-3 flex justify-content-center">
                    <Link to='/'><LogoSVG /></Link>
                </div>
                <div className="side-nav">
                    <Menu theme="light" defaultSelectedKeys={[activeMenu]} selectedKeys={[activeMenu]} mode="inline" onClick={onClose}>
                        <Menu.Item key={user?.type || 'dentist'} icon={<DashboardSVG />}>
                            <Link to={`/`}>Dashboard</Link>
                        </Menu.Item>
                        {
                            user?.type !== USER_ROLES.MANUFACTURER && (
                                <Menu.Item key="patients" icon={<PatientSVG />}>
                                    <Link to={`/${user?.type}/patients`}>Patients</Link>
                                </Menu.Item>
                            )
                        }
                        {
                            user?.type === USER_ROLES.DENTIST && (
                                <>
                                    {/* <Menu.Item key="orders" icon={<MyOrdersSVG />}>
                                        <Link to={`/${user?.type}/orders`}>My Orders</Link>
                                    </Menu.Item>
                                    <Menu.Item key="resources" icon={<ResourcesSVG />}>
                                        <Link to={`/${user?.type}/resources`}>Resources</Link>
                                    </Menu.Item> */}
                                </>
                            )
                        }
                    </Menu>
                </div>
            </Drawer>
            <Layout className={`site-layout ${collapsed ? 'side-nav-collapsed' : ''}`}>
                <Header className="app-header bg-white fixed pl-5 pr-5 z-1">
                    <div className="flex justify-content-between">
                        <div className='hidden xl:block'>

                            {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: toggleSidebar,
                            })}<span className="ml-2">{collapsed ? 'Show menu' : 'Hide menu'}</span>
                        </div>
                        <div className='xl:hidden'>

                            {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: toggleSideDrwer,
                            })}<span className="ml-2">{collapsed ? 'Show menu' : 'Hide menu'}</span>
                        </div>
                        <div>
                            <div className="flex align-items-center">
                                <Typography.Text className="mr-3 cursor-pointer text-sm px-2">Help</Typography.Text>
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <div className="profile">
                                        {user?.profilePic ? <Avatar size="large" src={user?.profilePic} />
                                            : <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                        }
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </Header>
                <Content className="content-section ml-3 mr-3 lg:ml-5 lg:mr-5">
                    {children}
                </Content>
                {/* <Footer style={{ textAlign: 'center' }}>Built with ❤️ at 32Co.</Footer> */}
            </Layout>
        </Layout>
    )
}

export default DashboardLayout;