import React, { useState, useEffect } from 'react'
import {useHistory} from 'react-router-dom';
import { Form, Button, message, Upload, Input, Select, notification, AutoComplete, Spin } from 'antd';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { IAddress } from '../constants';
import { useRegisterMutation } from '../services/authApi';
import { useGetProfileSignedUrlMutation } from '../services/authApi';
import {useUploadFileMutation} from '../../../services/api';
import AuthLayout from '../../../components/layout/auth-layout/auth-layout.component';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from "use-places-autocomplete";
import '../dentist-signup/signup.styles.less';

const { Option } = Select;

const ManufacturerSignupPage = ((props: any) => {
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [profilePic, setProfilePic] = useState('');
    const [isProfilePicUploading, setIsProfilePicUploading] = useState(false);
    const [formData, setFormData]: any = useState({ type: 'manufacturer' })
    const [register, { isLoading }] = useRegisterMutation();
    const [getProfileSignedUrl] = useGetProfileSignedUrlMutation();
    const [uploadFile] = useUploadFileMutation();
    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {},
        debounce: 300,
    });
    const [form] = Form.useForm();
    const { push } = useHistory();
    
    const onFinishFailed = (errorInfo: any) => {
        // console.log('Failed:', errorInfo);
    };

    const onFinish = async (values: any) => {
        setFormSubmitting(true);
        const { type, exp } = formData
        const { confirmPassword, business, ...personalDetails } = values;

        const data = {
            type,
            personalDetails, 
            profilePic,
            business
        }

        try {
            const response = await register(data).unwrap();
            if(response) {
                push('/signup-success');
            }
        } catch (error: any) {
            if(error) {
                error.data.errors.map((err: any) => {
                    notification.error({
                        message: 'Error',
                        description: err.message,
                        placement: 'topRight'
                    });
                })
            }
        }
        setFormSubmitting(false);
    }

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    const beforeProfileUpload = (file: any) => {
        const supportedFileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!supportedFileType) {
            message.error('You can only upload JPG/PNG/JPEG file!', 5);
            return;
        }
        const fileSize = file.size / 1024 / 1024 < 5;
        if (!fileSize) {
            message.error('Image must smaller than 5MB!', 5);
            return;
        }
        return supportedFileType && fileSize;
    };

    const profileOnChange = (info: any) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false);
        }
    };

    const profileRequest = async ({ file, onSuccess }: any) => {
        onSuccess("ok");
        if (file) {
            try {
                if(profilePic) {
                    setProfilePic('');
                }
                setIsProfilePicUploading(true);
                const fileData = {
                    fileName: file.name, 
                    mimeType: file.type, 
                    folderName: 'profile'
                };
                let signedUrl = await getProfileSignedUrl(fileData).unwrap();
                await uploadFile({url: signedUrl.url, file: file});
                const imageUrl = signedUrl && signedUrl.url.split('?')[0];
                if(imageUrl) {
                    setIsProfilePicUploading(false);
                }
                setProfilePic(imageUrl);
            } catch (e) {
                setIsProfilePicUploading(false);
                console.log('Upload profile pic error', e)
            }
        }
    };

    const onPreview = async (file: any) => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const handleAddressChange = (val: any) => {
        setValue(val);
    }

    const handleAddressSelect = ({ description }: any) => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        // setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
            .then(async (results) => {
                const latLng = await getLatLng(results[0])
                const zipCode = await getZipCode(results[0], false)
                return {...latLng, zipCode}
            })
            .then(({ lat, lng, zipCode }) => {
                const address: IAddress = {};
                address["formattedAddress"] = description
                address["latitude"] = lat
                address["longitude"] = lng
                address["zipCode"] = zipCode
                address["postCode"] = zipCode

                form.setFieldsValue({
                    address
                })
            })
            .catch((error) => {
                // console.log("😱 Error: ", error);
            });
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <AuthLayout>
            <div className="signup-page">
                <div className="grid mt-0">
                    <div className="col-12">
                        <div className="signup-right-container">
                            <div className="signup-form-container">
                                <h2 className="signup-text">Manufacturer Sign Up</h2>
                                {/* <HalfBorder width={45} /> */}

                                <Form
                                    name="manufacturer-signup"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    size='large'
                                    layout='vertical'
                                >
                                    <div className="steps-content">
                                        <div className="grid">
                                            <div className="col-12 sm:col-12 md:col-6">
                                                <Form.Item name="firstName" label="Contact First Name *"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input first name',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="John" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 sm:col-12 md:col-6">
                                                <Form.Item
                                                    name="lastName"
                                                    label="Contact Last Name *"
                                                    // hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input last name',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Doe" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 sm:col-12 md:col-6">
                                                <Form.Item
                                                    name="password"
                                                    label="Password *"
                                                    // hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input password',
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password placeholder="Enter Password" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 sm:col-12 md:col-6">
                                                <Form.Item
                                                    name="confirmPassword"
                                                    label="Confirm Password *"
                                                    dependencies={['password']}
                                                    // hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input confirm password',
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue('password') === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                            },
                                                        })
                                                    ]}
                                                >
                                                    <Input.Password placeholder="Enter Confirm Password" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 sm:col-12 md:col-6">
                                                <Form.Item name={["email", "email"]} label="Email Address *"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input email address',
                                                        },
                                                        { type: 'email', message: 'Please enter valid email address' }
                                                    ]}
                                                >
                                                    <Input placeholder="Enter your email address" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 sm:col-12 md:col-6">
                                                <Form.Item name={["business", "name"]} label="Business Name">
                                                    <Input placeholder="Enter Business Name" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 sm:col-12 md:col-6">
                                                <Form.Item label="Mobile Number *"
                                                // noStyle
                                                >
                                                    <Input.Group compact>
                                                        <Form.Item
                                                            name={['mobile', 'ext']}
                                                            noStyle
                                                            rules={[{ required: true, message: 'ext is required' }]}
                                                        >
                                                            <Select style={{ width: '30%' }} placeholder="ext">
                                                                <Option value="+44">
                                                                    <img src="https://cdn-icons-png.flaticon.com/512/555/555417.png" alt="UK falg" style={{ width: 20 }} className="mr-1" /> +44
                                                                </Option>
                                                                <Option value="+1">
                                                                    <img src="https://cdn-icons-png.flaticon.com/512/206/206626.png" alt="US falg" style={{ width: 20 }} className="mr-1" /> +1
                                                                </Option>
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={['mobile', 'number']}
                                                            noStyle
                                                            rules={[{ required: true, message: 'Please enter your mobile number' }]}
                                                        >
                                                            <Input style={{ width: '70%' }} placeholder="Enter your mobile number" />
                                                        </Form.Item>
                                                    </Input.Group>
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 sm:col-12 md:col-6">
                                                <Form.Item
                                                    label="Business Address "
                                                    name={['business', 'address', 'formattedAddress']}
                                                >
                                                    {/* <Input placeholder="Enter clinic address" /> */}
                                                    {/* import { AutoComplete } from 'antd'; */}
                                                    <AutoComplete value={value} onChange={handleAddressChange} disabled={!ready} placeholder="Enter first line of your address">
                                                        {status === "OK" && data.map((suggestion) => {
                                                            const { place_id, structured_formatting: { main_text, secondary_text },
                                                            } = suggestion;
                                                            return (
                                                                <AutoComplete.Option className="places-options" key={place_id} onClick={() => handleAddressSelect(suggestion)} value={`${main_text} ${secondary_text ? secondary_text : ''}`}>
                                                                    {/* <strong>{main_text}</strong>&nbsp;<small>{secondary_text}</small> */}
                                                                    <li key={place_id} onClick={() => handleAddressSelect(suggestion)}>
                                                                        <strong>{main_text}</strong> <small>{secondary_text}</small>
                                                                    </li>
                                                                </AutoComplete.Option>
                                                            );
                                                        })}
                                                    </AutoComplete>
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 sm:col-12 md:col-6">
                                                <Form.Item name={['business', 'address', "postcode"]} label="Business Postcode">
                                                    <Input placeholder="Enter your postcode" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 sm:col-12 md:col-6">
                                                <Form.Item name={['business', 'address', "country"]} label="Business Country">
                                                    <Input placeholder="Enter country" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 sm:col-12 md:col-6">
                                                <Form.Item label="Would you like to upload a profile picture?" name="profilePic" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                                    <ImgCrop rotate>
                                                        <Upload
                                                            listType="picture-card"
                                                            showUploadList={false}
                                                            beforeUpload={beforeProfileUpload}
                                                            customRequest={profileRequest}
                                                            onChange={profileOnChange} 
                                                            onPreview={onPreview}
                                                            >
                                                            {profilePic ? (
                                                                <img
                                                                    src={profilePic}
                                                                    alt="avatar"
                                                                    style={{ width: "100%", height: "100px" }}
                                                                />
                                                            ) : isProfilePicUploading ? <Spin size="small" /> : uploadButton}
                                                        </Upload>
                                                    </ImgCrop>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="steps-action">
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" loading={formSubmitting}>
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
})

export default ManufacturerSignupPage