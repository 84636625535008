// import { render } from '@testing-library/react';
import { Button, Card } from 'antd';
import { Link } from 'react-router-dom';
import './signup-success.styles.less';

export const SignupSuccessPage = () => {
    return (        
        <div className="signup-success">
            <h1 className="logo">32Co.</h1>
            <Card style={{ width: 480 }} bordered={false} >
                <div className="success-message-container">
                    {/* <div className="title-container p-mb-4"> */}
                        <span className="title">Welcome to 32Co</span>
                        <div className="half-border"></div>
                    {/* </div> */}
                    <p className="content p-mt-5">
                    You can now log in with your password and email. If you have not already completed your onboarding, you will be contacted by 32Co to do so
                    </p>
                    <Button type="primary" size="large" style={{minWidth: 180}} className="p-mt-3">
                        <Link to="/signin">LOGIN</Link>
                    </Button>
                </div>
            </Card>
        </div>
    );
};

export default SignupSuccessPage