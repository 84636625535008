export const GDP_SIGNUP_FORM_STEPS = 4
export const DESIGNER_SIGNUP_FORM_STEPS = 2
export const SPECIALIST_SIGNUP_FORM_STEPS = 2

export const USER_ROLES = {
    DENTIST: 'dentist',
    DESIGNER: 'designer',
    SPECIALIST: 'specialist',
    MANUFACTURER: 'manufacturer',
}

export const DENTAL_SCHOOL_LIST = ["Aberdeen", "Belfast", "Bristol", "Birmingham", "Cardiff", "Dundee", "Glasgow", "Leeds", "Liverpool", "London Kings", "London Barts", "Manchester", "Newcastle", "Plymouth", "Preston", "Sheffield", "Other"]

export const FIREBASE = {
    FIREBASE_APIKEY: "AIzaSyD4CgI9Cu5HWADmz93Qhn-Zs4ySvxTig3M",
    FIREBASE_AUTHDOMAIN: "co-chat-59998.firebaseapp.com",
    FIREBASE_STORAGEBUCKET: "co-chat-59998.appspot.com",
    FIREBASE_MESSAGINGSENDERID: "3557578791",
    FIREBASE_APPID: "1:3557578791:web:2200a726ad6682fa761602",
    FIREBASE_MEASUREMENTID: "G-P0F7V8P083",
    FIREBASE_PROJECT_ID: "co-chat-59998",
    FIREBASE_PRIVATE_KEY: "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC+7J0p/CyzhdCg\noMVNkVqqRGYbWEUDGRN0aiH4VrikfuhMO9Lfu6Ni7de9UoTPzyGnJLDMLN+Zv/g+\nRaaWRbFacZKSmtLD6qNGUppq9SqSVbf7pczTGjtoPUYUk/qDMZTTQJzKZV1zmNP7\nnOUONzcnvD3N1iTY8ZLrlKlIfrLBtsEe3moP8YmmC8SY6sOv34pUzj9OW/58TRRp\nFuiLNVyx8kXROHcmJzAF2oiLp3sGs1BpMVQ1cGLGR3luQyptHlVXnuHSiiTC9qHe\n9AqmYq5EPC6hn8bVsWQSD+L2vxWOqWe7g59U5Hy/OjnfPEumA0CfNvGKgis4sWYX\n3D02+yzFAgMBAAECggEAL6BOHz5O91Rq49rfBPe8pmssDmexRXHQqtJb2XtmkXAv\n6e5lbta7je7XSZynUUBx/GQYb0JFX/Dlj8EnqmZFtNJOdiBFccZbBuyNpR81yK21\n2MI2CzYIujrIu9LjI61vWthMFGf/TRoDTUmI0QhAi7q3LxzbcbunQvmBVfp3BH+v\n2eoKxxDV/PCKQb8l24c7NVXo6NV7O50r8tThta7zzsxzMSm/OqfGGU8HpbhWY1PG\nHWwkbNDnC9xkBBa+riJfIpJN7jDMgC/DIyHK/i19KMCD89H8MvS2B/TfaWtYb0u2\ntl0EjfpBPvwC5NCrjAklvzGomHetWAyKyAsm0G4SwQKBgQDvq6x1R9QYT6qSyyG8\nFNyoGG1/3rnlJD8X1YTXOB7I39topFkNNaXPBwZ6hCLxZF4jgd5BwOXFB4yR397A\npJ0QfaDYscLwM4ZOCzAvwLO5984GRmCtYBtzzBybYQ5g9wV47giaE93fEjoLs2t+\nE0CR6wLVMgwBxH0F1QUCjKdOwQKBgQDL7rVgbM/82zZIDBAy8YPZVCKLtcO12hkC\ne9uALcASiN3uaQxFbYAwUE4NWiPAIx53k0AUdNN2/Qb0h7ikwe5junerEEEO3HBJ\ngmyytlqW00DFL4HqtLSObZ/TfqzSAYF5haj7QXWeyaopm/YxAntPtMXOkoN/qHQE\nfTwdlsJjBQKBgH2eb3/xK/uqFe9mxMG99YGtfAcfiDPP8bneUgg0A/6pRmjsazh/\nO+7s8bpZc68uOiFGOmYgYs4IjOcQfcJfBWvVTO8HDPO1LdnOB22gBROeDMBBNv7o\ndrsU6pGOJ2kvUBD4Qk3vx8TZ2s6zNc4Y7SkOEqgH2T8764OZCU0yx6aBAoGADOUb\nMu2ueR6jYArjO1VqMOPRC2SC64zO6AlM5938ty9QWTLrQ7iY0nVBejao7A1Mb+Mv\nuhtpA60cM3ohgsmAeW3hA0AT3QyzTPKiTOqa786L2ewXGvAyOo9T+9uWsfDGbxi0\nhq6s8U1vJRJnjtHJWlDj8RvylK/iJiaFFpnh0MECgYAXRHawTDWnyxN4c3+5KoGs\nSK0lzOKei8mNTXtvqG7qrImax1HXUavctRWhlk/tlGIrRqTUATSUwbX/7dgkPnQ6\nOo64XLXP1p0sW7ucoi9yfDI3lvofHQf6DBcLyY7hpU6MaMDZg/gtDiDXkktnltsC\na7v74u2r1msdgtuecCLcjw==\n-----END PRIVATE KEY-----\n",
    FIREBASE_CLIENT_EMAIL: "firebase-adminsdk-rpki1@co-chat-59998.iam.gserviceaccount.com",
    FIREBASE_DATABASE_URL: "https://co-chat-59998.firebaseio.com"
}
export const UK_PHONE_NUMBER_REGEX = /\s*(([+](\s?\d)([-\s]?\d)|0)?(\s?\d)([-\s]?\d){9}|[(](\s?\d)([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*/;
export const US_PHONE_NUMBER_REGEX = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
