import AuthenticatedRoute from '../../components/PrivateRoute';
import ManufacturerRoutes from './manufacturer-routes';
import DashboardLayout from '../../components/layout/dashboard-layout/dashboard-layout.component';

const ManufacturerModule = () => (
    <DashboardLayout>
        {ManufacturerRoutes.map(route => (
            <AuthenticatedRoute
                key={route.key}
                path={route.path}
                exact={route.exact}
                name={route.name}
                component={route.component}
                role={route.role}
            />
        ))}
    </DashboardLayout>
)

const nestedRoutes = [
    '/manufacturer/patients/:caseId',
]

export const manufacturerRoutesToCheck = ManufacturerRoutes.map(route => route.path).concat(...nestedRoutes);

export default ManufacturerModule;