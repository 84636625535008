import { FC } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import IRoute from '../interface/IRoute'
// import { IRoute } from '../routes'

const UnAuthenticatedRoute: FC<IRoute & RouteProps> = ({ component: Component, ...rest }) => {
  const { user } = useAuth()
  return (
    <Route {...rest} render={props => {
      if (user) {
          return <Redirect to={{ pathname: `/${user.type}` }} />
      }

      // unauthorised so return component
      return <Component {...props} />
    }} />
  )
}

export default UnAuthenticatedRoute;
