import React from 'react'
// import { Switch, Route } from 'react-router-dom'
import DashboardLayout from '../../components/layout/dashboard-layout/dashboard-layout.component';
import AuthenticatedRoute from '../../components/PrivateRoute';
import designer from './designer-routes';

const DesignerModule = () => (
    <DashboardLayout>
        {designer.map(route => (
            <AuthenticatedRoute
                key={route.key}
                path={route.path}
                exact={route.exact}
                name={route.name}
                component={route.component}
                role={route.role}
            />
        ))}
    </DashboardLayout>
)

const nestedRoutes = [
    '/designer/patients/:caseId',
    '/designer/patients/:caseId/design/:designId'
]

export const designerRoutesToCheck = designer.map(route => route.path).concat(...nestedRoutes);

export default DesignerModule;