import AuthenticatedRoute from '../../components/PrivateRoute';
import SpecialistRoutes from './specialist-routes';
import DashboardLayout from '../../components/layout/dashboard-layout/dashboard-layout.component';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useGetUserProfileMutation } from '../../services/api';
import { updateUserData } from '../auth/authSlice';

const SpecialistModule = () => {
    const dispatch = useDispatch();
    const [getUserProfile, { isLoading }] = useGetUserProfileMutation();

    useEffect(() => {
        const loadProfile = async () => {
            const userResponse: any = await getUserProfile({}).unwrap()
            dispatch(updateUserData(userResponse))
        }
        loadProfile();
    }, [])
    
    return ( <DashboardLayout>
        {SpecialistRoutes.map(route => (
            <AuthenticatedRoute
                key={route.key}
                path={route.path}
                exact={route.exact}
                name={route.name}
                component={route.component}
                role={route.role}
            />
        ))}
    </DashboardLayout>
)}

export const specialistRoutesToCheck = SpecialistRoutes.map(route => route.path);

export default SpecialistModule;