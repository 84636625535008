import React from 'react';
import { Form, Select, Tag, Button, InputNumber } from 'antd';
import { IExperience } from '../constants';

const { Option } = Select;

const options = [{ color: 'gold', value: 'General clinical training' }, { color: 'lime', value: 'Clear aligner science' }, { color: 'green', value: 'Marketing clear aligners' }, { color: 'cyan', value: 'Clear aligner workflow' }, { color: 'gold', value: 'Advanced training ' }, { color: 'lime', value: 'Practical tips' }];


interface IExperienceComponentProps {
    initialValues: IExperience,
    submittedValues: Function,
    handleNextButton: Function,
    handleBackButton: Function
}

const ExperienceForm = ((props: IExperienceComponentProps) => {
    const { initialValues } = props;

    const tagRender = (props: any) => {
        const { label, value, closable, onClose, color } = props;
        const onPreventMouseDown = (event: any) => {
            event.preventDefault();
            event.stopPropagation();
        };

        const index = Math.floor(Math.random() * 4);

        return (
            <Tag
                // color={value}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    }

    const [form] = Form.useForm();

    const onFinish = (values: IExperience) => {
        props.submittedValues('exp', values);
        props.handleNextButton();
    }

    const onFinishFailed = ({ errorFields }: any) => {
        form.scrollToField(errorFields[0].name);
    };

    const storeValues = async () => {
        const values = await form.getFieldsValue();
        props.submittedValues('exp', values);
        props.handleBackButton();
    }

    return (
        <Form 
            form={form} 
            onFinish={onFinish} 
            onFinishFailed={onFinishFailed} 
            initialValues={initialValues}
            layout="vertical"
            size="large"
            className="mt-3 fadein animation-duration-1000"
        >
            <div className="grid">
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name={["practicesYear"]} label="How many years have you been practicing? *"
                        rules={[
                            {
                                required: true,
                                message: 'Please select number of practising',
                            },
                        ]}
                    >
                        <InputNumber min={0} placeholder="Enter number of years" style={{width: '100%'}}/>
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name={["totalClearAlignerCases"]} label="How many clear aligner cases have you completed to date? *"
                        rules={[
                            {
                                required: true,
                                message: 'Please select number of cases completed',
                            },
                        ]}
                    >
                        <Select placeholder="Select an option">
                            <Option value={0}>None completed</Option>
                            <Option value={10}>Fewer Than 10</Option>
                            <Option value={11}>More Than 10</Option>
                            <Option value={50}>More Than 50</Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="topics" label="Which topics would you like to learn more about?">
                        <Select mode="multiple" placeholder="Select an option" tagRender={tagRender} options={options} />
                    </Form.Item>
                </div>
            </div>
            <div className="steps-action">
            <Form.Item>
                <Button type="default" className="previoud-btn" onClick={storeValues} >
                    Back
                </Button>
                <Button type="primary" htmlType="submit">
                    Next
                </Button>
            </Form.Item>
            </div>
        </Form>
    )
}

)



export default ExperienceForm;