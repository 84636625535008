import { UserResponse } from './../modules/auth/services/authApi';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../redux/store';
import { formatUrl } from '../helpers';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://api-dev.32co.com'
// const BASE_URL = 'http://localhost:8001'
// const BASE_URL = 'http://localhost:8002'
// const BASE_URL = 'http://localhost:8003'
// const BASE_URL = 'http://localhost:8005'
// const BASE_URL = 'http://localhost:8006'
export const VERSION = process.env.REACT_APP_BASE_URL_VERSION || 'v1'

export interface SignedURLRequest {
  fileName: any,
  mimeType: any,
  folderName: string
}

export interface UploadFileRequest {
  url: string,
  file: any
}

export interface ISkus {
  createdAt: string,
  description: string,
  discounts: any,
  id: string,
  name: string,
  planPrice: any,
  refinementPrice: any,
  skuId: string,
  status: string,
  updatedAt: string,
  lastUpdatedBy: any
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers: any, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', token)
      }
      return headers
    },
  }),
  tagTypes: ['Patients', 'Proposals', 'Modifications', 'CaseInvitations', 'Discounts', 'Notes'],
  endpoints: (builder) => ({
    getSignedUrl: builder.mutation<any, SignedURLRequest>({
      query: (credentials) => ({
        url: formatUrl('/signed-url'),
        method: 'POST',
        body: credentials,
      }),
    }),
    uploadFile: builder.mutation<any, UploadFileRequest>({
      query: (credentials) => ({
        url: credentials.url,
        method: 'PUT',
        body: credentials.file,
        headers: { "Content-Type": "multipart/form-data" },
      }),
    }),
    getUserProfile: builder.mutation<UserResponse, any>({
      query: () => `/user/${VERSION}/profile`,
      // providesTags: (result, error, id) => [{ type: 'Patients', id }],
    }),
  }),
});

export const { useGetSignedUrlMutation, useUploadFileMutation, useGetUserProfileMutation } = api;