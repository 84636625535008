import { VERSION } from './../../../services/api';
import { IMobile } from './../../../services/patientsApi';
import {api} from '../../../services/api';
import { IAddress } from '../constants';

export interface SignedURLRequest {
  fileName: any,
  mimeType: any,
  folderName: string
}

export interface IPersonalDetails {
  firstName: string,
  lastName: string,
  password: string,
  email: {
    email: string
  },
  mobile: IMobile
}

export interface User {
  firstName: string,
  lastName: string,
  type: string,
  profilePic: string,
  status: string,
  referralCode: string
  id: string,
}

export interface UserResponse {
  user: User
  token: string
}

export interface LoginRequest {
  email: string
  password: string,
  remember?: boolean | null
}

export interface DentistRegisterRequest {
  type: string,
  personalDetails: any,
  exp: any,
  clinics: any,
  preferences: Object
}

export interface DesignerRegisterRequest {
  type: string,
  personalDetails: IPersonalDetails,
  business: {
    address: IAddress
  },
  bankDetails: any,
  profilePic: string,
  designLabName: string
}

export interface ManufacturerRequest {
  personalDetails?: any,
  type: string,
  profilePic?: string,
  business?: any,
}

export interface SpecialistRequest {
  personalDetails?: any,
  type: string,
  profilePic?: string,
  business?: any,
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: `/user/${VERSION}/login`,
        method: 'POST',
        body: credentials,
      }),
    }),
    register: builder.mutation<any, DentistRegisterRequest | DesignerRegisterRequest | ManufacturerRequest | SpecialistRequest>({
      query: (credentials: any) => ({
        url: `/user/${VERSION}/register`,
        method: 'POST',
        body: credentials,
      }),
    }),
    getProfileSignedUrl: builder.mutation<any, SignedURLRequest>({
      query: (credentials) => ({
        url: `/user/${VERSION}/signed-url`,
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
})

export const { useLoginMutation, useRegisterMutation, useGetProfileSignedUrlMutation } = authApi
