import { FC } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import IRoute from '../interface/IRoute'
// import { IRoute } from '../routes'

const PrivateRoute: FC<IRoute & RouteProps> = ({ component: Component, ...rest }) => {
  const { user } = useAuth()

  return (
    <Route {...rest} render={props => {
      if (!user) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />
      }

      // check if route is restricted by role
      if (user.type !== rest.role) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: `/not-authorized` }} />
      }

      // authorised so return component
      return <Component {...props} />
    }} />
  )
}

export default PrivateRoute;
