import React, { useState } from 'react';
import { Form, Input, Button, Upload, message, Spin } from 'antd';
import { IBankAccountDetails } from '../constants';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useGetProfileSignedUrlMutation } from '../services/authApi';
import {useUploadFileMutation} from '../../../services/api';

interface ISpecialistAccountDetailsComponentProps {
    initialValues?: IBankAccountDetails,
    submittedValues: Function,
    handleNextButton: Function,
    handleBackButton: Function,
    formSubmitting: boolean
}

const AccountDetailsForm = ((props: ISpecialistAccountDetailsComponentProps) => {

    const { initialValues, formSubmitting } = props;
    const [loading, setLoading] = useState(false);
    const [profilePic, setProfilePic] = useState('');
    const [isProfilePicUploading, setIsProfilePicUploading] = useState(false);
    const [getProfileSignedUrl] = useGetProfileSignedUrlMutation();
    const [uploadFile] = useUploadFileMutation();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.submittedValues('bankDetails', {...values, profilePic});
        props.handleNextButton(values);
    }

    const onFinishFailed = ({ errorFields }: any) => {
        form.scrollToField(errorFields[0].name);
    };

    const storeValues = async () => {
        const values = await form.getFieldsValue();
        props.submittedValues('account_details', values);
        props.handleBackButton();
    }

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    const beforeProfileUpload = (file: any) => {
        const supportedFileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!supportedFileType) {
            message.error('You can only upload JPG/PNG/JPEG file!', 5);
            return;
        }
        const fileSize = file.size / 1024 / 1024 < 5;
        if (!fileSize) {
            message.error('Image must smaller than 5MB!', 5);
            return;
        }
        return supportedFileType && fileSize;
    };

    const profileOnChange = (info: any) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false);
        }
    };

    const profileRequest = async ({ file, onSuccess }: any) => {
        onSuccess("ok");
        if (file) {
            try {
                if(profilePic) {
                    setProfilePic('');
                }
                setIsProfilePicUploading(true);
                const fileData = {
                    fileName: file.name, 
                    mimeType: file.type, 
                    folderName: 'profile'
                };
                let signedUrl = await getProfileSignedUrl(fileData).unwrap();
                await uploadFile({url: signedUrl.url, file: file});
                const imageUrl = signedUrl && signedUrl.url.split('?')[0];
                if(imageUrl) {
                    setIsProfilePicUploading(false);
                }
                setProfilePic(imageUrl);
            } catch (e) {
                setIsProfilePicUploading(false);
                console.log('Upload profile pic error', e)
            }
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );


    // const onPreview = async file => {
    //     let src = file.url;
    //     if (!src) {
    //         src = await new Promise(resolve => {
    //             const reader = new FileReader();
    //             reader.readAsDataURL(file.originFileObj);
    //             reader.onload = () => resolve(reader.result);
    //         });
    //     }
    //     const image = new Image();
    //     image.src = src;
    //     const imgWindow = window.open(src);
    //     imgWindow.document.write(image.outerHTML);
    // };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={initialValues}
            layout="vertical"
            size="large"
            className="mt-3 fadein animation-duration-500"
        >
            <div className="grid">
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="bankName" label="Bank Name">
                        <Input placeholder="Enter bank name" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="bankCountry" label="Bank Country">
                        <Input placeholder="Enter bank country" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="accountHolderName" label="Name of account holder">
                        <Input placeholder="Account holder" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="accountNumber" label="Account number">
                        <Input placeholder="Account number" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="sortCode" label="Sort code (if UK bank)">
                        <Input placeholder="Enter sort code" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item name="iban" label="IBAN (if international)">
                        <Input placeholder="Enter IBAN" />
                    </Form.Item>
                </div>
                <div className="col-12 sm:col-12 md:col-6">
                    <Form.Item label="Would you like to upload a profile picture?" name="profilePic" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                        <ImgCrop rotate>
                            <Upload
                                listType="picture-card"
                                showUploadList={false}
                                beforeUpload={beforeProfileUpload}
                                customRequest={profileRequest}
                                onChange={profileOnChange} >
                                {profilePic ? (
                                    <img
                                        src={profilePic}
                                        alt="avatar"
                                        style={{ width: "100%", height: "100px" }}
                                    />
                                ) : isProfilePicUploading ? <Spin size="small" /> : uploadButton}
                            </Upload>
                        </ImgCrop>
                    </Form.Item>
                </div>
            </div>
            <div className="steps-action">
                <Form.Item>
                    <Button type="default" className="previoud-btn" onClick={storeValues} >
                        Back
                    </Button>
                    <Button type="primary" htmlType="submit" loading={formSubmitting}>
                        Submit
                    </Button>
                </Form.Item>
            </div>
        </Form>
    )
})

export {AccountDetailsForm}