import moment from 'moment'
import {message} from 'antd';

export const beforeFileUpload = (file: any) => {
    const supportedFileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!supportedFileType) {
        message.error('You can only upload JPG/PNG/JPEG file!', 5);
        return;
    }
    const fileSize = file.size / 1024 / 1024 < 5;
    if (!fileSize) {
        message.error('Image must smaller than 5MB!', 5);
        return;
    }
    return supportedFileType && fileSize;
};

export const calculateDueIn = (createdAt: string) => {
    const date1 = (moment());
    const date2 = (moment(createdAt));
    let dueTime = 'overdue';
    if(date1.diff(date2, 'minutes') < 60) {            
        dueTime = date1.diff(date2, 'minutes') + ' mins'
    }else if(date1.diff(date2, 'hours') > 0 && date1.diff(date2, 'hours') < 48) {
        dueTime = 48 - date1.diff(date2, 'hours') + ' hrs';
    }else if(date1.diff(date2, 'days') < 2) {
        dueTime = date1.diff(date2, 'days') + ' days';
    }
    return dueTime;
}

export const formatDate = (date: string | undefined, fr: string) => {
    return moment(date).format(fr)
}

export const roundDecimalNumber = (number: number) => {
    const roundOfNumber = Math.round(number * 10);
    return roundOfNumber / 10;
}