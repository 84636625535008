import { roundDecimalNumber } from "../utils";
import { VERSION } from '../services/api';

export const IPRCSVparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, "_")
};

export const handleIPRCSVFileChange = (data: any, fileInfo: any) => {

    const maxilla: any = [];
    let maxillaHeaders: any = [];
    const mandible: any = [];
    let mandibleHeaders: any = [];

    let isMandible = false;


    data.map((d: any) => {
        if (d.maxilla === "Mandible") {
            isMandible = true;
        }
        if (!isMandible) {
            if (d.maxilla !== "Total") {

                maxilla.push(d.__parsed_extra);
            }
        } else {
            if (d.maxilla !== "Total" && d.maxilla !== "Mandible") {
                mandible.push(d.__parsed_extra);
            }
        }
    })


    maxillaHeaders = [...maxilla.splice(0, 1)[0]].filter((d: any) => {
        return d != null;
    });
    mandibleHeaders = [...mandible.splice(0, 1)[0]].filter((d: any) => {
        return d != null;
    });


    const maxillaArrayObject: any = maxilla.map((m: any) => {
        let obj: any = {}
        m.map((d: any, i: any) => {
            obj[maxillaHeaders[i]] = d
        })
        return obj;
    });

    const mandibleArrayObject: any = mandible.map((m: any) => {
        let obj: any = {}
        m.map((d: any, i: any) => {
            obj[mandibleHeaders[i]] = d
        })
        return obj;
    })

    const finalMaxillaArrayObject = maxillaArrayObject.map((res: any, i: any) => {
        const obj = {
            '18m-17d': roundDecimalNumber(parseFloat(res['18m'] ? res['18m'] : 0) + parseFloat(res['17d'] ? res['17d'] : 0)),
            '17m-16d': roundDecimalNumber(parseFloat(res['17m'] ? res['17m'] : 0) + parseFloat(res['16d'] ? res['16d'] : 0)),
            '16m-15d': roundDecimalNumber(parseFloat(res['16m'] ? res['16m'] : 0) + parseFloat(res['15d'] ? res['15d'] : 0)),
            '15m-14d': roundDecimalNumber(parseFloat(res['15m'] ? res['15m'] : 0) + parseFloat(res['14d'] ? res['14d'] : 0)),
            '14m-13d': roundDecimalNumber(parseFloat(res['14m'] ? res['14m'] : 0) + parseFloat(res['13d'] ? res['13d'] : 0)),
            '13m-12d': roundDecimalNumber(parseFloat(res['13m'] ? res['13m'] : 0) + parseFloat(res['12d'] ? res['12d'] : 0)),
            '12m-11d': roundDecimalNumber(parseFloat(res['12m'] ? res['12m'] : 0) + parseFloat(res['11d'] ? res['11d'] : 0)),
            '11m-21m': roundDecimalNumber(parseFloat(res['11m'] ? res['11m'] : 0) + parseFloat(res['21m'] ? res['21m'] : 0)),
            '21d-22m': roundDecimalNumber(parseFloat(res['21d'] ? res['21d'] : 0) + parseFloat(res['22m'] ? res['22m'] : 0)),
            '22d-23m': roundDecimalNumber(parseFloat(res['22d'] ? res['22d'] : 0) + parseFloat(res['23m'] ? res['23m'] : 0)),
            '23d-24m': roundDecimalNumber(parseFloat(res['23d'] ? res['23d'] : 0) + parseFloat(res['24m'] ? res['24m'] : 0)),
            '24d-25m': roundDecimalNumber(parseFloat(res['24d'] ? res['24d'] : 0) + parseFloat(res['25m'] ? res['25m'] : 0)),
            '25d-26m': roundDecimalNumber(parseFloat(res['25d'] ? res['25d'] : 0) + parseFloat(res['26m'] ? res['26m'] : 0)),
            '26d-27m': roundDecimalNumber(parseFloat(res['26d'] ? res['26d'] : 0) + parseFloat(res['27m'] ? res['27m'] : 0)),
            '27d-28m': roundDecimalNumber(parseFloat(res['27d'] ? res['27d'] : 0) + parseFloat(res['28m'] ? res['28m'] : 0)),
        }
        return obj;
    })

    const finalMandibleArrayObject = mandibleArrayObject.map((res: any, i: any) => {
        const obj = {
            '48m-47d': roundDecimalNumber(parseFloat(res['48m'] ? res['48m'] : 0) + parseFloat(res['47d'] ? res['47d'] : 0)),
            '47m-46d': roundDecimalNumber(parseFloat(res['47m'] ? res['47m'] : 0) + parseFloat(res['46d'] ? res['46d'] : 0)),
            '46m-45d': roundDecimalNumber(parseFloat(res['46m'] ? res['46m'] : 0) + parseFloat(res['45d'] ? res['45d'] : 0)),
            '45m-44d': roundDecimalNumber(parseFloat(res['45m'] ? res['45m'] : 0) + parseFloat(res['44d'] ? res['44d'] : 0)),
            '44m-43d': roundDecimalNumber(parseFloat(res['44m'] ? res['44m'] : 0) + parseFloat(res['43d'] ? res['43d'] : 0)),
            '43m-42d': roundDecimalNumber(parseFloat(res['43m'] ? res['43m'] : 0) + parseFloat(res['42d'] ? res['42d'] : 0)),
            '42m-41d': roundDecimalNumber(parseFloat(res['42m'] ? res['42m'] : 0) + parseFloat(res['41d'] ? res['41d'] : 0)),
            '41m-31m': roundDecimalNumber(parseFloat(res['41m'] ? res['41m'] : 0) + parseFloat(res['31m'] ? res['31m'] : 0)),
            '31d-32m': roundDecimalNumber(parseFloat(res['31d'] ? res['31d'] : 0) + parseFloat(res['32m'] ? res['32m'] : 0)),
            '32d-33m': roundDecimalNumber(parseFloat(res['32d'] ? res['32d'] : 0) + parseFloat(res['33m'] ? res['33m'] : 0)),
            '33d-34m': roundDecimalNumber(parseFloat(res['33d'] ? res['33d'] : 0) + parseFloat(res['34m'] ? res['34m'] : 0)),
            '34d-35m': roundDecimalNumber(parseFloat(res['34d'] ? res['34d'] : 0) + parseFloat(res['35m'] ? res['35m'] : 0)),
            '35d-36m': roundDecimalNumber(parseFloat(res['35d'] ? res['35d'] : 0) + parseFloat(res['36m'] ? res['36m'] : 0)),
            '36d-37m': roundDecimalNumber(parseFloat(res['36d'] ? res['36d'] : 0) + parseFloat(res['37m'] ? res['37m'] : 0)),
            '37d-38m': roundDecimalNumber(parseFloat(res['37d'] ? res['37d'] : 0) + parseFloat(res['38m'] ? res['38m'] : 0)),
        }
        return obj;
    })

    const result: { step: null | number, data: any }[] = []
    for (let i = 0; i < finalMaxillaArrayObject.length; i++) {
        const maxillaObj = finalMaxillaArrayObject[i];
        const mandibleObj = finalMandibleArrayObject[i];

        let hasValues = false;

        for (let key in maxillaObj) {
            const val = maxillaObj[key] * 10
            if (val > 0) {
                hasValues = true
            }
        }

        if (!hasValues) {
            for (let key in mandibleObj) {
                const val = mandibleObj[key] * 10
                if (val > 0) {
                    hasValues = true
                }
            }
        }

        if (hasValues) {
            result.push({
                step: i,
                data: {
                    ...finalMaxillaArrayObject[i] as {},
                    ...finalMandibleArrayObject[i] as {}
                }
            })
        }

    }

    return {iprData: result, totalStep: finalMaxillaArrayObject.length};
}

export const handleTMCSVFileChange = (data: any, fileInfo: any) => {

    const maxilla: any = [];
    const maxillaLeftItems: any = [];
    let maxillaHeaders: any = [];

    const mandible: any = [];
    let mandibleHeaders: any = [];
    const mandibleLeftItems: any = [];

    let isMandible = false;
    data.map((d: any) => {
        if (d.maxilla === "Mandible") {
            isMandible = true;
        }
        if (!isMandible) {
            maxilla.push(d.__parsed_extra);
            maxillaLeftItems.push(d.maxilla);
        } else {
            if (d.maxilla !== "Mandible") {
                mandible.push(d.__parsed_extra);
                mandibleLeftItems.push(d.maxilla);
            }
        }
    });

    maxillaHeaders = [...maxilla.splice(0, 1)[0]].filter((d: any) => {
        return d != null;
    });
    maxillaHeaders.unshift(18);

    mandibleHeaders = [...mandible.splice(0, 1)[0]].filter((d: any) => {
        return d != null;
    });
    mandibleHeaders.unshift(48)

    return {
        maxillaLeftItems,
        mandibleLeftItems,
        maxillaHeaders,
        mandibleHeaders,
        maxilla,
        mandible
    };
}


export const onFormValuesChange = (values: any, obj: any) => {
    const formChangedKey = Object.keys(values)[0];
    let dataToUpdate;
    if (typeof values[formChangedKey] === 'object') {
        dataToUpdate = {
            ...obj,
            [formChangedKey]: {
                ...obj[formChangedKey],
                ...values[formChangedKey]
            }
        }
    } else {
        dataToUpdate = {
            ...obj,
            ...values
        }
    }
    return dataToUpdate;
};

export const formatSaveImage = (data: any[] = []) => {
    console.log('formatSaveImage data', data)
    return data.map((item: any) => ({ 
        url: item.url, 
        type: "", 
        name: item.name
    }));
}

export const renderValue = (value: string | undefined) => {
    return value ? value : '-'
}

export const formatUrl = (path: string) => {
    const userType = localStorage.getItem('userType');
    console.log('userType', userType);
    let type = '';
    if (userType) {
        type = JSON.parse(userType);
    }
    console.log('type', type);

    if(path.includes('conversation')){
        return `/user/${VERSION}${path}`;
    }
    return `/${type}/${VERSION}${path}`;
}