import React from 'react';
import IRoute from '../../interface/IRoute';
import SignupComponent from './dentist-signup/dentist.component';
import DesignerSignupPage from './designer-signup/designer.component';
import ManufacturerSignupPage from './manufacturer-signup/manufacturer.component';
import SigninComponent from './signin/signin.component';
import SpecialistSignupPage from './specialist-signup/specialist.component';

const authRoutes: IRoute[] = [
    {
        path: '/signin',
        exact: true,
        name: 'Signin Page',
        key: 'signin_page',
        component: () => <SigninComponent />
    },
    {
        path: '/signup',
        exact: true,
        name: 'Signup Page',
        key: 'signup_page',
        component: () => <SignupComponent />
    },
    // {
    //     path: '/forgot-password',
    //     exact: true,
    //     name: 'Forgot Page',
    //     key: 'forgot_page',
    //     component: ForgotPasswordPage
    // },
    // {
    //     path: '/reset-password',
    //     exact: true,
    //     name: 'Reset Page',
    //     key: 'reset_page',
    //     component: ResetPasswordPage
    // },
    {
        path: '/signup/designer',
        exact: true,
        name: 'Designer Signup Page',
        key: 'designer_signup_page',
        component: () => <DesignerSignupPage />
    },
    {
        path: '/signup/specialist',
        exact: true,
        name: 'Specialist Signup Page',
        key: 'specialist_signup_page',
        component: () => <SpecialistSignupPage />
    },
    {
        path: '/signup/manufacturer',
        exact: true,
        name: 'Manufacturer Signup Page',
        key: 'manufacturer_signup_page',
        component: () => <ManufacturerSignupPage />
    }
]

// import IRoute from "../interfaces/route"
// import {SigninPage, SignupPage, ForgotPasswordPage, ResetPasswordPage, DesignLabSignupPage, ManufacturerSignupPage, SpecialistSignupPage } from "../pages/auth";

export default authRoutes;