import * as React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Form, Input, Button, Card, notification, Checkbox } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { setCredentials } from '../authSlice'
import { useLoginMutation } from '../services/authApi'
import type { LoginRequest } from '../services/authApi'
import './signin.styles.less';
import AuthLayout from '../../../components/layout/auth-layout/auth-layout.component';

export const SigninComponent = () => {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const [login, { isLoading }] = useLoginMutation();

    const onSubmit = async (formState: LoginRequest) => {
        try {
            const userResponse = await login(formState).unwrap()
            dispatch(setCredentials(userResponse))
            localStorage.setItem('userType', JSON.stringify(userResponse?.user?.type));
            push('/')
        } catch (err) {
            notification.error({
                message: 'Error',
                description:
                    'Please check username and password!',
                placement: 'topRight'
            });
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    //   const handleChange = ({
    //     target: { name, value },
    //   }: React.ChangeEvent<HTMLInputElement>) =>
    //     setFormState((prev) => ({ ...prev, [name]: value }))

    return (
        <AuthLayout>
            <div className="login-page fadein animation-duration-500">
                <Card style={{ width: 420 }} bordered={false}>
                    <div className="p-mb-5">
                        <h2 className="form-heading font-semibold">Login</h2>
                        {/* <HalfBorder width={45} /> */}
                    </div>
                    <Form
                        name="basic"
                        onFinish={onSubmit}
                        onFinishFailed={onFinishFailed}
                        size='large'
                        layout='vertical'
                        autoComplete="off"
                        className="fadein animation-duration-500 w-full"
                    >
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input placeholder="example@gmail.com" suffix={<MailOutlined />} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="Password"
                            className="mt-2"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="******" />
                        </Form.Item>

                        <Form.Item className="mt-2">
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                            <Link to="/forgot-password" style={{ float: 'right' }}>Forgot password</Link>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block loading={isLoading}>
                                LOGIN
                            </Button>
                        </Form.Item>
                        <div className="p-mt-5 create-account-container mt-2">
                            <span>Not registered yet?</span>
                            <Link to='/signup'><span>Create an account</span></Link>
                        </div>
                    </Form>
                </Card>
            </div>
        </AuthLayout>
    )
}

export default SigninComponent
