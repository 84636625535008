// import IRoute from "../interfaces/route"
import React, {Suspense} from "react";
import { USER_ROLES } from "../../config/constants";
import IRoute from "../../interface/IRoute";
const LazyDashboardComponent = React.lazy(() => import('./dashboard/dashboard.component'));
const LazyPatientsComponent = React.lazy(() => import('./patients/patients.component'));
const LazyTreatmentDesignComponent = React.lazy(() => import('./patients/patient-details/treatment-design-detail.component'));
const LazySubmitCaseComponent = React.lazy(() => import('./submit-case/submit-case.component'));
const LazyProfileComponent = React.lazy(() => import('./profile/profile.component'));
// import HomePage from "../pages/home/home.component";
// import {CasesPage} from '../pages/cases'
// import PatientPage from "../pages/case-info/patient.component";
// import ProposalDetailsPage from '../pages/proposal-details/proposal-details.component'
// import { SubmitCasePage } from "../pages/submit-case";
// import submitNewDesign from "../pages/cases/submit-new-design";

const gdpRoutes: IRoute[] = [
    {
        path: '/dentist',
        exact: true,
        name: 'Dashboard',
        key: 'dashboard_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyDashboardComponent /></Suspense>),
        role: USER_ROLES.DENTIST
    },
    {
        path: '/profile',
        exact: true,
        name: 'Profile',
        key: 'profile_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyProfileComponent /></Suspense>),
        role: USER_ROLES.DENTIST
    },
    {
        path: '/dentist/patients',
        exact: false,
        name: 'Patients',
        key: 'patients_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazyPatientsComponent /></Suspense>),
        role: USER_ROLES.DENTIST
    },
    {
        path: '/dentist/patients/submit/:type',
        exact: true,
        name: 'Submit Case Page',
        key: 'submit_case_page',
        component: () => (
            <Suspense fallback={() => <div>Loading...</div>}><LazySubmitCaseComponent /></Suspense>),
        role: USER_ROLES.DENTIST
    },
    // {
    //     path: '/patients/:caseId/proposals/:proposalId',
    //     exact: true,
    //     name: 'Proposal Detail Page',
    //     key: 'proposal_detail_page',
    //     component: ProposalDetailsPage
    // },
    // {
    //     path: '/patients/case/submit-case',
    //     exact: true,
    //     name: 'Submit Case',
    //     key: 'submit_case',
    //     component: SubmitCasePage
    // },
    // {
    //     path: '/patients/case/submit-design/:caseId',
    //     exact: true,
    //     name: 'Submit New Design',
    //     key: 'submit_new_design',
    //     component: submitNewDesign
    // },
]

export default gdpRoutes;